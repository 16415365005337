<template>
    <ContentWrapper :class="{'start-special': startSpecial}">
        <breadcrumb/>
        <div class="container">

            <ticket-sale-banner v-if="$store.getters.user('country') === 'CH'"></ticket-sale-banner>

            <outstanding-order-browser></outstanding-order-browser>
            <order-payment-links v-if="dashboard.order_payment_links.length" :orders="dashboard.order_payment_links"></order-payment-links>

            <div v-if="!$store.getters.user('email_verified')"
                 class="alert alert-link show mb-3 animated-fast fadeInDown p-0" role="alert">
                <strong><i class="fad fa-exclamation-triangle "></i> {{ $t('common.view.dashboard.verified.email.not') }}</strong> {{ $t('common.view.dashboard.verified.email.check') }}
                <br/><a @click.prevent="sendVerificationMail()"
                        href="" v-if="!verification_sent"><u>{{ $t('common.view.dashboard.verified.email.send_verification_mail') }}</u></a>
            </div>

            <b-row>
              <b-col :xl="dashboard.plants_for_sale.length > 0 ? 9 : 12" :lg="dashboard.plants_for_sale.length > 0 ? 8 : 12">
                <b-row>
                  <b-col xl="4" sm="6" cols="12">
                    <wallet-balance-fiat :balance="dashboard.balances.fiat" class="animated-fast fadeInLeft"></wallet-balance-fiat>
                  </b-col>
                  <b-col xl="4" sm="6" cols="12">
                    <div class="card card-default card-stat animated-fast fadeInDown">
                      <div class="card-header">
                        {{ $t('common.view.dashboard.plants') }} <i class="card-header-icon cai-grow"></i>
                      </div>
                      <router-link :to="{name: 'Growing:PlantBrowser'}" class="card-body py-0">

                        <i v-if="dashboard.has_upgradeable_plants" class="fad fa-exclamation-triangle text-danger mr-2"></i>

                        <span v-if="dashboard.amount_plants === 0">{{ dashboard.amount_plants }}</span>

                        <span class="mr-2" v-if="dashboard.plants.level_1 > 0">
                                          <img src="../modules/Growing/asstes/svg/plant-levels/plant-level-1.svg" width="30px; margin-top: -5px" class="mw-100 mr-1">
                                          <formatted-number :amount="dashboard.plants.level_1" type="plants" animated=""/>
                                      </span>
                        <span class="mr-2" v-if="dashboard.plants.level_2 > 0">
                                          <img src="../modules/Growing/asstes/svg/plant-levels/plant-level-2.svg" width="30px; margin-top: -5px" class="mw-100 mr-1">
                                          <formatted-number :amount="dashboard.plants.level_2" type="plants" animated=""/>
                                      </span>
                        <span class="mr-2" v-if="dashboard.plants.level_3 > 0">
                                          <img src="../modules/Growing/asstes/svg/plant-levels/plant-level-3.svg" width="30px; margin-top: -5px" class="mw-100 mr-1">
                                          <formatted-number :amount="dashboard.plants.level_3" type="plants" animated=""/>
                                      </span>
                      </router-link>
                      <div class="card-footer p-0 text-center">
                        <b-btn :to="{name: 'Growing:PlantBrowser'}"
                               variant="link" v-b-tooltip.hover
                               title="Manage plants"
                               class="float-right bg-white px-1 py-0 m-1"><i class="fad fa-tools fa-2x"></i>
                        </b-btn>
                      </div>
                    </div>
                  </b-col>
                  <b-col xl="4" sm="6" cols="12">
                    <wallet-balance-cannabis :balance="dashboard.balances.cbd" class="animated-fast fadeInRight"></wallet-balance-cannabis>
                  </b-col>
                  <b-col xl="4" sm="6" cols="12" v-if="$store.getters.hasFirstline">
                    <div class="card card-default card-stat animated-fast fadeInLeft">
                      <div class="card-header">
                        {{ $t('common.view.dashboard.team.total') }} <i class="card-header-icon cai-user"></i>
                      </div>
                      <router-link :to="{name: 'Affiliate:Team'}" class="card-body">
                        <formatted-number :amount="dashboard.referrals" type="members" animated=""/>
                      </router-link>
                    </div>
                  </b-col>
                  <b-col xl="4" sm="6" cols="12" v-if="$store.getters.hasFirstline">
                    <div class="card card-default card-stat animated-fast fadeInUp">
                      <div class="card-header">
                        {{ $t('common.view.dashboard.team.plants') }} <i class="card-header-icon cai-grow"></i>
                      </div>
                      <router-link :to="{name: 'Affiliate:Team'}" class="card-body">
                        <formatted-number class="hide-on-hover animated-fast fadeInLeft" :amount="dashboard.team_plants_active" type="plants" animated=""/>
                        <div class="show-on-hover animated-fast fadeInLeft">
                          <formatted-number :amount="dashboard.team_plants_sold" type="plants" animated=""/>
                          <small>(sales)</small>
                        </div>
                      </router-link>
                    </div>
                  </b-col>
                  <b-col xl="4" sm="6" cols="12" v-if="$store.getters.hasFirstline">
                  <div class="card card-default card-stat animated-fast fadeInUp">
                    <div class="card-header">
                      {{ $t('common.view.dashboard.team.profit') }} <i class="card-header-icon cai-user"></i>
                    </div>
                    <router-link :to="{name: 'Affiliate:Team'}" class="card-body">
                      <formatted-number :amount="dashboard.profit_referrals" type="fiat" animated=""/>
                    </router-link>
                  </div>
                </b-col>
                </b-row>
              </b-col>
              <b-col xl="3" lg="4"  v-if="dashboard.plants_for_sale.length > 0">
                  <div class="bg-white rounded">
                    <plant-overview-card :plant="dashboard.plants_for_sale[0]"></plant-overview-card>
                  </div>
              </b-col>
            </b-row>

            <div class="mt-4 animated-fast fadeInUp">
                <carousel :perPageCustom="[[0,1],[480,2], [1024,3]]">
                    <slide v-for="(post, index) in posts" :key="index" class="p-2 text-center">
                        <post-preview :post="post"></post-preview>
                    </slide>
                </carousel>
            </div>

            <div class="text-right animated-fast fadeInUp" :class="{'mt-4': posts.length < 4}" style="animation-delay: 600ms"
                 v-show="dashboard.posts.length">
                <router-link tag="a" :to="{name: 'News:Posts'}">{{ $t('common.view.dashboard.blog.view_all') }} <i
                    class="fad fa-arrow-right"></i></router-link>
            </div>

        </div>
    </ContentWrapper>
</template>
<style lang="scss">

.VueCarousel-wrapper {
    margin-bottom: -35px;
    padding-bottom: 10px;
}

.VueCarousel-dot {
    background-color: #999999 !important;

    &.VueCarousel-dot--active {
        background-color: #323232 !important;
    }
}

.popup-youtube {

    background: white;
    box-sizing: border-box;
    resize: horizontal;

    overflow: auto;
    width: 100%;
    max-width: 1024px;

    padding: 0;

    .swal2-content {
        width: 100%;
        padding-bottom: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
    }
}

</style>
<script>

import WalletBalanceFiat from '../modules/Wallet/components/BalanceFiat';
import WalletBalanceCannabis from '../modules/Wallet/components/BalanceCannabis';

import {Carousel, Slide} from 'vue-carousel';
import PlantOverviewCard from "../modules/Growing/components/PlantOverviewCard";
import OrderPaymentLinks from "@/modules/Payment/components/OrderPaymentLinks";
import OutstandingOrderBrowser from "../modules/Wallet/components/OutstandingOrderBrowser";
import PostPreview from "@/modules/News/components/PostPreview.vue";
import TicketSaleBanner from "@/modules/Growing/components/TicketSaleBanner.vue";

export default {
    name: 'Dashboard',
    components: {
      TicketSaleBanner,
      PostPreview,
      OutstandingOrderBrowser,
      OrderPaymentLinks,
      PlantOverviewCard,
      WalletBalanceFiat,
      WalletBalanceCannabis,
      Carousel,
      Slide
    },
    data() {
        return {
            posts: [],
            dashboard: {
                order_payment_links: [],
                plants_left: 0,
                team_plants_sold: 0,
                team_plants_active: 0,
                balances: {
                    fiat: null,
                    cbd: null
                },
                plants: {
                    level_1: 0,
                    level_2: 0,
                    level_3: 0
                },
                amount_plants: 0,
                referrals: 0,
                activity: [],
                profit_plants: 0,
                profit_referrals: 0,
                posts: [],
                meeting: null,
                has_upgradeable_plants: false,
                plants_for_sale: []
            },
            verification_sent: false,
            filter: this.$options.filters,
            numberTransitionDuration: 200,
            startSpecial: false
        }
    },
    mounted() {

        this.$api.get('news/posts?language=' + this.$i18n.locale).then(response => {
          this.posts = response.data.data;
          this.loading = false;
        });

        this.$api.get('user/dashboard').then((response) => {
            this.dashboard = response.data;
            this.dashboard.ref_link = process.env.VUE_APP_REFERRAL_SHORT_URL + response.data.ref_hash;
        }).catch((error) => {
            if (error.data && error.data.message) {
                this.$swal.fire('Error', error.data.message, 'error');
            }
        });
    },
    methods: {
        goToSpecial() {
            this.startSpecial = true;
            this.$router.push({name: 'Growing:Special'});
        },
        sendVerificationMail() {
            this.verification_sent = true;
            this.$api.post('auth/request-email-verification').then((response) => {
                this.$swal.fire(this.$t('common.view.dashboard.popup.verification_mail_sent.title'), this.$t('common.view.dashboard.popup.verification_mail_sent.description'), 'success');
            })
        },
    }
}

</script>