<template>
  <div class="card card-default animated-fast fadeInDown">
    <div class="card-header">
       Pending Shop Orders
      <i class="card-header-icon cai-shop"></i>
    </div>
    <div class="card-body">
      <b-row v-for="order in orders" v-show="!order.deleted" :key="order.label">
        <b-col>
          <strong>{{ order.label }}</strong> | {{ order.amount | currency }} {{ order.currency }}
        </b-col>
        <b-col md="5">
          <address>
            {{order.delivery_address.firstname}} {{order.delivery_address.lastname}},
            {{order.delivery_address.address1}},
            <template v-if="order.delivery_address.address2">
              {{order.delivery_address.address2}},
            </template>
            {{order.delivery_address.postcode}} {{order.delivery_address.city}}
          </address>
        </b-col>
        <b-col class="text-right">
          <b-btn @click="deleteOrder(order)" variant="link" ><i class="fad fa-trash-alt text-secondary text-secondary" style="font-size: 1.3em"></i></b-btn>
          <b-btn :to="{name: 'Payment', params: {ref: order.label}}" variant="primary" ><i class="fad fa-cash-register text-secondary text-secondary mr-2" style="font-size: 1.3em"></i> Pay now</b-btn>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ['orders'],
  methods: {
    deleteOrder(order) {
      this.$swal.fire({
        title: 'Confimation',
        text: `Are you sure you want to delete the order with id ${order.label}?`,
        showCancelButton: true,
      }).then(confirmation => {
        if (confirmation.value) {
          this.$api.delete(`growing/shop/order/${order.label}`).then((response) => {
            order.deleted = true;
          }).catch((error) => {
            this.$swal.fire('Error', error.data.message, 'error');
          });
        }
      });
    }
  }
}
</script>
